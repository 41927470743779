// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAnalytics } from 'firebase/analytics';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
	apiKey: 'AIzaSyDdvguyYV-mf9dZooq5efn7T3s-CridURs',
	authDomain: 'chaggebi-teashop.firebaseapp.com',
	projectId: 'chaggebi-teashop',
	storageBucket: 'chaggebi-teashop.appspot.com',
	messagingSenderId: '689267691285',
	appId: '1:689267691285:web:d9f2e62dfa777ffc48631c',
};

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);
export const firestore = getFirestore(firebaseApp);
export const firebaseAnalytics = getAnalytics(firebaseApp);
