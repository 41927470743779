export const getUTMParams = () => {
	const params = new URLSearchParams(window.location.search);
	const utmParams = {
		utm_source: params.get('utm_source'),
		utm_medium: params.get('utm_medium'),
		utm_campaign: params.get('utm_campaign'),
		utm_term: params.get('utm_term'),
		utm_content: params.get('utm_content'),
	};
	if (
		utmParams.utm_source == null &&
		utmParams.utm_medium == null &&
		utmParams.utm_campaign == null &&
		utmParams.utm_term == null &&
		utmParams.utm_content == null
	) {
		return null;
	}
	return utmParams;
};
