import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export const useRelocate = () => {
	const location = useLocation();
	const isRelocateToStore = useMemo(() => {
		const searchParams = new URLSearchParams(location.search);
		const relocateParam = searchParams.get('relocate');
		const isRelocateToStore = relocateParam === 'store' || location.pathname === '/store';
		return isRelocateToStore;
	}, [location]);
	return { isRelocateToStore };
};
