import { useEffect } from 'react';
import { isAndroid, isIOS } from 'react-device-detect';
import { Features } from '../components/features/Features';
import { STORE_LINK } from '../components/hero/GotoStores';
import { Hero } from '../components/hero/Hero';
import { MalmalStudio } from '../components/malmal-studio/MalmalStudio';
import { Synopsys } from '../components/synopsys/Synopsys';
import { useRelocate } from '../hooks/useRelocate';
import { Footer } from '../layouts/footer/Footer';
import { NavBar } from '../layouts/navbar/Navbar';
import { useUtmRecording } from '../hooks/useUtmRecording';

export const LandingPage = () => {
	useUtmRecording();
	const { isRelocateToStore } = useRelocate();

	useEffect(() => {
		if (isRelocateToStore) {
			if (isAndroid) {
				window.open(STORE_LINK.Android, '_blank');
			} else if (isIOS) {
				window.open(STORE_LINK.IOS, '_blank');
			}
		}
	}, [isRelocateToStore]);

	return (
		<>
			<NavBar />
			<Hero />
			<Synopsys />
			<Features />
			<MalmalStudio />
			<Footer />
		</>
	);
};
