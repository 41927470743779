import { ChakraProvider } from '@chakra-ui/react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { CouponPage } from './pages/CouponPage';
import { LandingPage } from './pages/LandingPage';
import { OpensourceUsagePage } from './pages/OpensourceUsagePage';
import { PrivacyEnPage, PrivacyKrPage } from './pages/PrivacyPage';
import { TermsOfServiceEnPage, TermsOfServiceKrPage } from './pages/TermsOfServicePage';
import { CustomGlobalStyle, Fonts, extendedTheme } from './theme';

export const App = () => (
	<BrowserRouter>
		<Fonts />
		<ChakraProvider theme={extendedTheme}>
			<CustomGlobalStyle />
			<Routes>
				<Route path="/" element={<LandingPage />} />
				<Route path="/store" element={<LandingPage />} />
				<Route path="/coupon" element={<CouponPage />} />
				<Route path="/privacy-eng" element={<PrivacyEnPage />} />
				<Route path="/privacy" element={<PrivacyKrPage />} />
				<Route path="/terms-of-service-eng" element={<TermsOfServiceEnPage />} />
				<Route path="/terms-of-service" element={<TermsOfServiceKrPage />} />
				<Route path="/oss-notice" element={<OpensourceUsagePage />} />
			</Routes>
		</ChakraProvider>
	</BrowserRouter>
);
