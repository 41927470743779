import { useEffect } from 'react';
import { getUTMParams } from '../utils/utm';
import { logEvent } from 'firebase/analytics';
import { firebaseAnalytics } from '../storage/firebase';

export const useUtmRecording = () => {
	useEffect(() => {
		const utmParams = getUTMParams();
		if (utmParams !== null) {
			console.log('UTM Params: ', utmParams);
			logEvent(firebaseAnalytics, 'utm_params', utmParams);
		}
	}, []);
};
