import { extendTheme } from '@chakra-ui/react';
import { theme } from '@chakra-ui/react';
import { Global } from '@emotion/react';

export const Fonts = () => (
	<Global
		styles={`
				@font-face {
						font-family: 'Katuri';
						src: url('https://gcore.jsdelivr.net/gh/projectnoonnu/noonfonts_13@1.0/Katuri.woff') format('woff');
						font-weight: normal;
						font-style: normal;
						font-display: swap;
				}

				@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100..900&display=swap')
				`}
	/>
);

export const CustomGlobalStyle = () => (
	<Global
		styles={`
				u {
					box-shadow: inset 0 -0.5rem 0 rgba(255, 220, 219, 0.8);
					text-decoration: none;
				}
				`}
	/>
);

export const extendedTheme = extendTheme({
	...theme,
	fonts: {
		katuri: `'Katuri', 'Noto Sans KR', sans-serif`,
		notosans: `'Noto Sans KR', sans-serif`,
	},
	sizes: {
		max: 'max-content',
		min: 'min-content',
		full: '100%',
		'9xs': '2rem',
		'8xs': '4rem',
		'7xs': '6rem',
		'6xs': '8rem',
		'5xs': '10rem',
		'4xs': '12rem',
		'3xs': '14rem',
		'2xs': '16rem',
		xs: '20rem',
		sm: '24rem',
		md: '28rem',
		lg: '32rem',
		xl: '36rem',
		'2xl': '42rem',
		'3xl': '48rem',
		'4xl': '56rem',
		'5xl': '64rem',
		'6xl': '72rem',
		'7xl': '80rem',
		'8xl': '90rem',
		container: {
			sm: '640px',
			md: '768px',
			lg: '1024px',
			xl: '1280px',
		},
	},
});
